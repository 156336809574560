<!--
  @name: 用户登录的表单组件
  @description: 用户登录的表单组件
  @author: 马群
  @time: 2024-04-09 19:44:54
-->
<template>
  <div class="gtm-login__login-from">
    <div class="tab-box">
      <el-tabs v-model="activeLoginType" @tab-click="onLoginTypeClick">
        <el-tab-pane label="手机号登录" name="mobileLogin"></el-tab-pane>
        <el-tab-pane label="账号登录" name="userNameLogin"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="form-box">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" :key="formKey">
        <div class="login-form-box" v-if="activeLoginType === 'mobileLogin'">
          <el-form-item :span="12" prop="tel" class="tel-form-item">
            <el-select class="tel-area-code-box" v-model="telAreaCode" placeholder="请选择">
              <el-option label="+86" value="+86"></el-option>
            </el-select>
            <el-input
              class="input-mobile"
              maxlength="11"
              placeholder="请输入手机号"
              v-model.trim="ruleForm.tel"
              autocomplete="on"
              clearable
              @input="filterNumericInput"
            >
            </el-input>
          </el-form-item>
          <el-form-item :span="12" prop="captcha">
            <el-input class="captcha-input" v-model="ruleForm.captcha" maxlength="6" placeholder="请输入验证码">
              <el-button :disabled="mobileRegisterCaptchaDisabled" type="text" slot="append" @click="onCaptchaClick">
                获取验证码<span v-if="isHasEffectCode">({{ effectiveDurationTime }})</span>
              </el-button>
            </el-input>
            <div class="verify-code-error-msg" v-if="isHasEffectCode">
              <i class="el-icon-success"></i>
              <div>验证码已发送至您的手机，请注意查收！</div>
            </div>
          </el-form-item>
        </div>
        <div class="login-form-box username-box" v-if="activeLoginType === 'userNameLogin'">
          <el-form-item :span="12" prop="userName">
            <el-input
              placeholder="请输入账号名/手机号"
              v-model="ruleForm.userName"
              autocomplete="new-password"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item :span="12" prop="password">
            <el-input
              class="input-password"
              v-model="ruleForm.password"
              name="password"
              key="password"
              placeholder="请输入密码"
              show-password
              autocomplete="new-password"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item :span="12" prop="isChecked">
          <el-checkbox v-model="ruleForm.isChecked" class="checkedClass">
            我已经阅读并同意
            <span @click.prevent.stop="onOpenArgument('/platform/agreement')">《用户协议》</span>和<span
            @click.prevent.stop="onOpenArgument('/platform/privacy')"
          >《隐私政策》</span
          >
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-button class="login-btn" type="normal" @click="onLoginClick">登录</el-button>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'
import { gtmDebounce } from 'web-utils'

export default {
  name: 'LoginFormCom',
  components: {},
  mixins: [],
  props: {
    loginType: {
      type: String,
      default: 'mobileLogin'
    }
  },
  data () {
    // 用户协议表单校验
    let checkIsChecked = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请勾选用户协议'))
      } else {
        callback()
      }
    }
    let checkIsTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      if (value.length !== 11) {
        return callback(new Error('请输入11位手机号'))
      }
      if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error('手机号格式错误，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      activeLoginType: 'mobileLogin',
      telAreaCode: '+86',

      // 验证码倒计时
      isHasEffectCode: false,
      effectiveDurationTime: 59,

      publicKey: '',
      loginLoading: false,

      ruleForm: {
        tel: '',
        captcha: '',
        isChecked: false
      },
      formKey: 1,
      rules: {
        tel: [{ validator: checkIsTel, trigger: 'blur' }],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'change' },
          { min: 6, max: 6, message: '请输入6位验证码', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入用户名/手机号', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 1 到 16 个字符', trigger: 'blur' }
        ],
        isChecked: [{ validator: checkIsChecked, trigger: 'change' }]
      }
    }
  },
  computed: {
    mobileRegisterCaptchaDisabled () {
      return this.ruleForm.tel.length !== 11 || this.isHasEffectCode
    }
  },
  mounted () {
    if (this.loginType) {
      this.activeLoginType = this.loginType
    }
    this.formKey++
  },
  methods: {
    filterNumericInput (value) {
      this.ruleForm.tel = value.replace(/[^0-9]/g, '')
    },
    onLoginTypeClick () {
      this.formKey++
      this.ruleForm = {
        tel: '',
        captcha: '',
        userName: '',
        password: '',
        isChecked: ''
      }
      this.$refs.ruleForm.clearValidate()
      this.$emit('handle-login-tab-change', this.activeLoginType)
    },
    onOpenArgument (dialogType) {
      let { href } = this.$router.resolve({
        path: dialogType
      })
      window.open(href, '_blank')
    },
    onCaptchaClick: gtmDebounce(function () {
      this.$refs.ruleForm.validateField('tel', valid => {
        // 这里有点特殊，valid返回的message信息，判断为空的情况下就是通过，否则不通过
        if (valid !== '') {
          return false
        } else {
          this.requestSendSmsCode()
        }
      })
    }, 300),
    requestLoginOut () {
      let path = ''
      if (process.env.VUE_APP_ENV === 'production') {
        path = 'https://xmgl.glodon.com'
      } else {
        path = 'https://aecloud-test.glodon.com'
      }
      let params = {
        userToken: 1,
        serviceUrl: path
      }
      this.$guaranteeApi.PORTAL.fetchPortalLoginOut(path, params)
    },
    onLoginClick: gtmDebounce(function () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loginLoading = true
          this.$emit('handle-login-loading-change', this.loginLoading)
          this.requestLoginOut()
          if (this.activeLoginType === 'mobileLogin') {
            this.requestUserLogin()
          } else {
            this.getPublicKey()
          }
        } else {
          return false
        }
      })
    }, 300),
    // 请求-发送验证码
    requestSendSmsCode () {
      let body = {
        mobile: this.ruleForm.tel,
        captchaType: 'LOGIN_CLOUT'
      }
      this.$baseAppApi.USER.fetchUserSendSmsCode({}, body)
        .then(res => {
          if (res.code === 0) {
            this.isHasEffectCode = true
            let validInterval = setInterval(() => {
              this.effectiveDurationTime -= 1
              if (this.effectiveDurationTime <= 0) {
                clearInterval(validInterval)
                this.isHasEffectCode = false
                this.effectiveDurationTime = 59
              }
            }, 1000)
          }
        })
    },
    // 请求-用户登录
    requestUserLogin () {
      let body = {}
      if (this.activeLoginType === 'mobileLogin') {
        body = {
          authType: 'MOBILE',
          tel: this.ruleForm.tel,
          captcha: this.ruleForm.captcha
        }
      } else {
        let password = this.encryptedData(this.publicKey, this.ruleForm.password)
        body = {
          authType: 'ACCOUNT',
          loginName: this.ruleForm.userName,
          password
        }
      }
      this.$baseAppApi.USER.fetchUserLogin({}, body)
        .then(res => {
          if (res.code === 0 && res.data) {
            this.$rootMethods.DATA_TRACKING.sendTrackData('1514002')
            location.href = res.data
          } else {
            this.loginLoading = false
            this.$emit('handle-login-loading-change', this.loginLoading)
          }
        })
        .finally(() => {
        })
    },
    encryptedData (publicKey, data) {
      // 新建JSEncrypt对象
      let encryptor = new JSEncrypt()
      // 设置公钥
      encryptor.setPublicKey(publicKey)
      // 加密数据
      return encryptor.encrypt(data)
    },
    getPublicKey () {
      this.$guaranteeApi.COMMON_LOGIN.fetchGetPublicKey({
        time: new Date() * 1
      })
        .then(res => {
          if (res.code === 0) {
            this.publicKey = res.public_key
            this.requestUserLogin()
          } else {
            this.loginLoading = false
            this.$emit('handle-login-loading-change', this.loginLoading)
          }
        })
        .finally(() => {
        })
    }
  }
}
</script>
<style lang="scss">
.gtm-login__login-from {
  .el-input__inner:hover {
    border-color: #165dff;
  }

  .tab-box {
    position: relative;
    width: 350px;
    top: 122px;
    left: 34px;

    .el-tabs__item {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }

    .el-tabs__item.is-active {
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #165dff;
    }

    .el-tabs__item:hover {
      color: #165dff;
    }

    .el-tabs__active-bar {
      padding: unset;
      left: unset;
      background-color: #165dff;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }

  .form-box {
    position: absolute;
    top: 196px;
    left: 50px;

    .el-input {
      width: 360px;
      height: 40px;
    }

    .login-form-box {
      height: 99px;

      .tel-form-item:hover {
        .tel-area-code-box {
          .el-input__inner {
            border-right: 1px solid #165dff;
            border-radius: 0px;
          }
        }

        .input-mobile {
          .el-input__inner {
            border-left: 1px solid #fff;
          }
        }
      }

      .tel-area-code-box {
        width: 76px;

        .el-input,
        .el-input__inner {
          width: 76px;
          padding-right: 0px;
        }

        .el-input__inner {
          border-right: 1px solid #fff;
          border-radius: 0px;
        }
      }

      .verify-code-error-msg {
        height: 40px;
        margin-top: 10px;
        margin-bottom: -7px;
        line-height: 40px;
        background: rgba(86, 181, 2, 0.2);
        border-radius: 2px;
        border: 1px solid rgba(86, 181, 2, 0.3);
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        display: flex;

        .el-icon-success {
          color: #56b502;
          font-size: 18px;
          margin: 10px 10px 0 20px;
        }
      }

      .captcha-input:hover {
        border-color: #165dff;
      }
    }

    .username-box {
      .el-input {
        width: 362px;
      }

      .el-input__inner {
        width: 362px;
      }
    }

    .input-password {
      .el-input__icon {
        margin-top: 2px;
      }

      //.el-icon-close-eyes {
      //  margin-top: 3px;
      //}
      //
      //.el-icon-view {
      //  margin-top: 1px;
      //}
    }

    .el-input-group__append {
      background-color: #fff;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #165dff;
      line-height: 24px;
      text-align: right;
      font-style: normal;
      margin-right: 10px;
      width: 52px;
      padding-left: 30px;
      height: 20px;

      .el-button {
        height: 20px;
        line-height: 0px;
        padding-left: 10px;
        border-left: 1px solid #d2d2d2;
        padding-right: 10px;
      }

      .is-disabled {
        color: #999999;
      }
    }

    .el-input__inner {
      height: 40px;
      line-height: 40px;
    }

    .el-form-item__error {
      background-color: unset;
    }

    .input-mobile {
      width: 286px;
      height: 40px;

      .el-input__inner {
        height: 40px;
        line-height: 40px;
        border-radius: 0px;
      }
    }

    .checkedClass {
      margin-top: 110px;

      .el-checkbox__label {
        span {
          color: #165dff;
        }
      }
    }

    .input-password {
      width: 362px;
      height: 42px;
      line-height: 42px;

      input {
        width: 362px;
        height: 42px;
      }

      .el-input__inner {
        width: 362px;
        height: 42px;
        line-height: 42px;
      }
    }

    &.input-password::before {
      content: '';
      background: url(../../../../assets/images/gtm-login/gtm-login-password.png) center center no-repeat;
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      bottom: 0px;
      position: absolute;
      z-index: 1;
    }

    .login-btn {
      width: 360px;
      height: 50px;
      background: #165dff;
      border-radius: 4px;
      font-family: MicrosoftYaHei;
      font-size: 18px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      font-style: normal;
    }

    .login-btn:hover {
      background: rgba(22, 93, 255, 0.8);
    }

    .login-btn:active {
      background: #124acc;
    }

    .el-checkbox__label {
      font-size: 14px;
    }
  }

  .el-input {
    font-size: 14px;
  }

  .el-input__icon {
    width: 25px;
    line-height: 34px;
  }
}
</style>
