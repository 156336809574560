<!--
  @name: 用户注册的表单组件
  @description: 用户注册的表单组件
  @author: 马群
  @time: 2024-04-09 19:44:54
-->
<template>
  <div class="gtm-login__register-from">
    <div v-if="!isRegisterSuccess" class="tab-box" :class="isUserNameRegisterClass ? 'tab-box--username-register' : ''">
      <el-tabs v-model="activeRegisterType" @tab-click="onLoginTypeClick">
        <el-tab-pane label="手机号注册" name="mobileRegister"></el-tab-pane>
        <el-tab-pane label="账号注册" name="userNameRegister"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="form-box" :class="isUserNameRegisterClass ? 'form-box--username-register' : ''">
      <el-form v-if="!isRegisterSuccess" ref="ruleForm" :model="ruleForm" :rules="rules" :key="formKey">
        <div class="register-form-box" v-if="activeRegisterType === 'mobileRegister'">
          <el-form-item :span="12" prop="tel" class="tel-form-item">
            <el-select class="tel-area-code-box" v-model="telAreaCode" placeholder="请选择">
              <el-option label="+86" value="+86"></el-option>
            </el-select>
            <el-input
              class="input-mobile"
              maxlength="11"
              placeholder="请输入手机号"
              v-model="ruleForm.tel"
              @input="filterNumericInput"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item :span="12" prop="captcha">
            <el-input v-model="ruleForm.captcha" class="captcha-input" maxlength="6" placeholder="请输入验证码">
              <el-button :disabled="mobileRegisterCaptchaDisabled" type="text" slot="append" @click="onCaptchaClick">
                获取验证码<span v-if="isHasEffectCode">({{ effectiveDurationTime }})</span>
              </el-button>
            </el-input>
            <div class="verify-code-error-msg" v-if="isHasEffectCode">
              <i class="el-icon-success"></i>
              <div>验证码已发送至您的手机，请注意查收！</div>
            </div>
          </el-form-item>
        </div>
        <div class="register-form-box username-box" v-if="activeRegisterType === 'userNameRegister'">
          <el-form-item :span="12" prop="userName">
            <el-tooltip
              :manual="true"
              v-model="userNameValid.isUserNameTooltip"
              placement="bottom"
              effect="light"
              popper-class="gtm-register-tooltip"
            >
              <el-input
                placeholder="请输入账号名,6-16个字符"
                v-model="ruleForm.userName"
                @input="onUserNameChange"
                @focus="onUserNameChange"
                @blur="onUserNameBlur"
                clearable
              ></el-input>
              <div slot="content">
                <div>
                  <i v-if="userNameValid.isUserNameCharacterValid" class="el-icon-success" style="color: green"></i>
                  <i v-else class="el-icon-error" style="color: red"></i>
                  <span style="margin-left: 10px">字母开头，与数字、下划线、短横线任意一类组合</span>
                </div>
                <div>
                  <i v-if="userNameValid.isUserNameLengthValid" class="el-icon-success" style="color: green"></i>
                  <i v-else class="el-icon-error" style="color: red"></i>
                  <span style="margin-left: 10px">长度为6～16个字符</span>
                </div>
              </div>
            </el-tooltip>
          </el-form-item>
          <el-form-item :span="12" prop="password">
            <el-tooltip
              :manual="true"
              v-model="passwordValid.isPasswordTooltip"
              placement="bottom"
              effect="light"
              popper-class="gtm-register-tooltip"
            >
              <el-input
                class="input-password"
                v-model="ruleForm.password"
                placeholder="请输入登录密码"
                name="password"
                key="password"
                show-password
                @input="onPasswordChange"
                @focus="onPasswordChange"
                @blur="onPasswordBlur"
              ></el-input>
              <div slot="content">
                <div>
                  <i v-if="passwordValid.isPasswordCharacterValid" class="el-icon-success" style="color: green"></i>
                  <i v-else class="el-icon-error" style="color: red"></i>
                  <span style="margin-left: 10px">数字、字母、特殊符号至少包含两种</span>
                </div>
                <div>
                  <i v-if="passwordValid.isPasswordLengthValid" class="el-icon-success" style="color: green"></i>
                  <i v-else class="el-icon-error" style="color: red"></i>
                  <span style="margin-left: 10px">长度为6～16个字符</span>
                </div>
              </div>
            </el-tooltip>
          </el-form-item>
          <el-form-item :span="12" prop="againPassword">
            <el-input
              class="input-password"
              v-model="ruleForm.againPassword"
              name="againPassword"
              key="password"
              placeholder="请再次输入登录密码"
              show-password
              autocomplete="new-password"
            ></el-input>
          </el-form-item>
          <el-form-item :span="12" prop="tel" class="tel-form-item">
            <el-select class="tel-area-code-box" v-model="telAreaCode" placeholder="请选择">
              <el-option label="+86" value="+86"></el-option>
            </el-select>
            <el-input
              class="input-mobile"
              maxlength="11"
              placeholder="请输入手机号"
              v-model="ruleForm.tel"
              clearable
              @input="filterNumericInput"
            >
            </el-input>
          </el-form-item>
          <el-form-item :span="12" prop="captcha">
            <el-input v-model="ruleForm.captcha" class="captcha-input" maxlength="6" placeholder="请输入验证码">
              <el-button :disabled="mobileRegisterCaptchaDisabled" type="text" slot="append" @click="onCaptchaClick">
                获取验证码<span v-if="isHasEffectCode">({{ effectiveDurationTime }})</span>
              </el-button>
            </el-input>
          </el-form-item>
          <div class="verify-code-error-msg" v-if="isHasEffectCode">
            <i class="el-icon-success"></i>
            <div>验证码已发送至您的手机，请注意查收！</div>
          </div>
        </div>
        <el-form-item :span="12" prop="isChecked">
          <el-checkbox
            v-model="ruleForm.isChecked"
            class="checkedClass"
            :class="activeRegisterType === 'userNameRegister' ? 'checkedClass--register' : ''"
          >
            我已经阅读并同意
            <span @click.prevent.stop="onOpenArgument('/platform/agreement')">《用户协议》</span>和<span
            @click.prevent.stop="onOpenArgument('/platform/privacy')"
          >《隐私政策》</span
          >
          </el-checkbox>
        </el-form-item>
      </el-form>
      <div v-if="isRegisterSuccess" class="register-success">
        <el-result icon="success" title="注册成功">
          <template slot="extra">
            <div class="register__result-info">
              <div>登录后建议您完成<span class="font-width: bold">个人认证、企业认证</span></div>
              <div>完成后可享受保函服务</div>
              <div class="register__result-ul"></div>
            </div>
          </template>
        </el-result>
      </div>
      <div v-if="isRegisterSuccess" class="register-success-btn">
        <el-button type="normal" @click="onGotoLogin">完成并登录</el-button>
      </div>
      <div v-else class="register-btn">
        <el-button type="normal" @click="onRegisterClick">注册</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from 'jsencrypt'
import { gtmDebounce } from 'web-utils'

export default {
  name: 'RegisterFormCom',
  components: {},
  props: {},
  data () {
    // 用户协议表单校验
    let checkIsChecked = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请勾选用户协议'))
      } else {
        callback()
      }
    }
    // 用户名规则校验
    let checkIsUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入用户名'))
        // eslint-disable-next-line
      } else if (!/^[a-zA-Z](?=.*[0-9_-])[a-zA-Z0-9_-]*$/.test(value)) {
        return callback(new Error('字母开头，与数字、下划线、短横线任意一类组合'))
      } else if (value.length < 6 || value.length > 16) {
        return callback(new Error('长度在 6 到 16 个字符'))
      } else {
        callback()
      }
    }
    // 密码规则校验
    let checkIsPassword = (rule, value, callback) => {
      const hasNumber = /\d/.test(value)
      const hasLetter = /[a-zA-Z]/.test(value)
      // eslint-disable-next-line
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\/\\\s-]/.test(value)

      // 至少包含两种类型的字符
      let isValid =
        (hasNumber && (hasLetter || hasSpecialChar)) || (hasLetter && hasSpecialChar) || (hasNumber && hasSpecialChar)
      if (!value) {
        return callback(new Error('请输入密码'))
      } else if (!isValid) {
        return callback(new Error('数字、字母、特殊符号至少包含两种'))
      } else if (value.length < 6 || value.length > 16) {
        return callback(new Error('长度在 6 到 16 个字符'))
      } else {
        callback()
      }
    }
    // 二次密码规则校验
    let checkIsAgainPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入二次确认密码'))
      } else if (this.ruleForm.password !== value) {
        return callback(new Error('二次输入密码不一致'))
      } else if (value.length < 6 || value.length > 16) {
        return callback(new Error('长度在 6 到 16 个字符'))
      } else {
        callback()
      }
    }
    let checkIsTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      if (value.length !== 11) {
        return callback(new Error('请输入11位手机号'))
      }
      if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error('手机号格式错误，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      // 注册方式
      activeRegisterType: 'mobileRegister',

      // 电话号区域号
      telAreaCode: '+86',

      // 验证码倒计时
      isHasEffectCode: false,
      effectiveDurationTime: 59,

      // 用户名校验
      userNameValid: {
        isUserNameTooltip: false,
        isUserNameLengthValid: false,
        isUserNameCharacterValid: false
      },
      // 密码校验
      passwordValid: {
        isPasswordTooltip: false,
        isPasswordLengthValid: false,
        isPasswordCharacterValid: false
      },
      isRegisterSuccess: false,
      registerLoading: false,
      // 注册表单
      formKey: 1,
      ruleForm: {
        tel: '',
        captcha: '',
        userName: '',
        password: '',
        againPassword: '',
        isChecked: ''
      },
      // 表单规则校验
      rules: {
        tel: [{ validator: checkIsTel, trigger: 'blur' }],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入6位验证码', trigger: 'blur' }
        ],
        userName: [{ validator: checkIsUserName, trigger: 'blur' }],
        password: [{ validator: checkIsPassword, trigger: 'blur' }],
        againPassword: [{ validator: checkIsAgainPassword, trigger: 'blur' }],
        isChecked: [{ validator: checkIsChecked, trigger: 'change' }]
      }
    }
  },
  computed: {
    mobileRegisterCaptchaDisabled () {
      return this.ruleForm.tel.length !== 11 || this.isHasEffectCode
    },
    isUserNameRegisterClass () {
      return this.activeRegisterType === 'userNameRegister' && !this.isRegisterSuccess
    }
  },
  methods: {
    filterNumericInput (value) {
      this.ruleForm.tel = value.replace(/[^0-9]/g, '')
    },
    // 切换注册方式
    onLoginTypeClick () {
      this.formKey++
      this.ruleForm = {
        tel: '',
        captcha: '',
        userName: '',
        password: '',
        isChecked: ''
      }
      this.$refs.ruleForm.clearValidate()
      this.$emit('handle-register-tab-change', this.activeRegisterType)
    },
    onCaptchaClick: gtmDebounce(function () {
      this.$refs.ruleForm.validateField('tel', valid => {
        // 这里有点特殊，valid返回的message信息，判断为空的情况下就是通过，否则不通过
        if (valid !== '') {
          return false
        } else {
          this.requestSendSmsCode()
        }
      })
    }, 300),
    // 点击注册按钮
    onRegisterClick: gtmDebounce(function () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.activeRegisterType === 'mobileRegister') {
            this.requestUserRegister()
          } else {
            this.getPublicKey()
          }
        } else {
          return false
        }
      })
    }, 300),
    // 点击用户协议跳转
    onOpenArgument (dialogType) {
      let { href } = this.$router.resolve({
        path: dialogType
      })
      window.open(href, '_blank')
    },

    onGotoLogin () {
      this.$emit('handle-login-click', this.activeRegisterType)
    },

    /** ***************************校验用户名*****************************/
    // 账号注册-输入用户名后触发提示
    onUserNameChange () {
      if (this.ruleForm.userName.length >= 1) {
        this.userNameValid.isUserNameTooltip = true
      } else {
        this.userNameValid.isUserNameTooltip = false
      }
      this.validateUserName()
    },
    // 账号注册-用户名失焦后隐藏提示
    onUserNameBlur () {
      this.userNameValid.isUserNameTooltip = false
    },
    // 账号注册-用户名校验
    validateUserName () {
      if (this.ruleForm.userName.length >= 6 && this.ruleForm.userName.length <= 16) {
        this.userNameValid.isUserNameLengthValid = true
      } else {
        this.userNameValid.isUserNameLengthValid = false
      }
      // eslint-disable-next-line
      const pattern = /^[a-zA-Z](?=.*[0-9_-])[a-zA-Z0-9_-]*$/
      console.log(this.ruleForm.userName, pattern.test(this.ruleForm.userName))
      if (pattern.test(this.ruleForm.userName)) {
        this.userNameValid.isUserNameCharacterValid = true
      } else {
        this.userNameValid.isUserNameCharacterValid = false
      }
    },
    /** ***************************校验密码*****************************/
    // 账号注册-输入密码后触发提示
    onPasswordChange () {
      if (this.ruleForm.password.length >= 1) {
        this.passwordValid.isPasswordTooltip = true
      } else {
        this.passwordValid.isPasswordTooltip = false
      }
      this.validatePassword()
    },
    // 账号注册-输入密码失焦后隐藏提示
    onPasswordBlur () {
      this.passwordValid.isPasswordTooltip = false
    },
    // 账号注册-输入密码后触发校验
    validatePassword () {
      if (this.ruleForm.password.length >= 6 && this.ruleForm.password.length <= 16) {
        this.passwordValid.isPasswordLengthValid = true
      } else {
        this.passwordValid.isPasswordLengthValid = false
      }
      if (this.isValidPasswordCharacter(this.ruleForm.password)) {
        this.passwordValid.isPasswordCharacterValid = true
      } else {
        this.passwordValid.isPasswordCharacterValid = false
      }
    },
    // 账号注册-密码校验核心
    isValidPasswordCharacter (str) {
      const hasNumber = /\d/.test(str)
      const hasLetter = /[a-zA-Z]/.test(str)
      // eslint-disable-next-line
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\/\\\s-]/.test(str)

      // 至少包含两种类型的字符
      return (
        (hasNumber && (hasLetter || hasSpecialChar)) || (hasLetter && hasSpecialChar) || (hasNumber && hasSpecialChar)
      )
    },
    encryptedData (publicKey, data) {
      // 新建JSEncrypt对象
      let encryptor = new JSEncrypt()
      // 设置公钥
      encryptor.setPublicKey(publicKey)
      // 加密数据
      return encryptor.encrypt(data)
    },
    getPublicKey () {
      this.$guaranteeApi.COMMON_LOGIN.fetchGetPublicKey({
        time: new Date() * 1
      })
        .then(res => {
          if (res.code === 0) {
            this.publicKey = res.public_key
            this.requestUserRegister()
          }
        })
    },
    // 请求-发送验证码
    requestSendSmsCode () {
      let body = {
        mobile: this.ruleForm.tel,
        captchaType: 'SIGNUP_CLOUT_BY_MOBILE'
      }
      this.$baseAppApi.USER.fetchUserSendSmsCode({}, body)
        .then(res => {
          if (res.code === 0) {
            this.isHasEffectCode = true
            let validInterval = setInterval(() => {
              this.effectiveDurationTime -= 1
              if (this.effectiveDurationTime <= 0) {
                clearInterval(validInterval)
                this.isHasEffectCode = false
                this.effectiveDurationTime = 59
              }
            }, 1000)
          }
        })
    },
    // 请求-用户注册
    requestUserRegister () {
      let body = {}
      if (this.activeRegisterType === 'mobileRegister') {
        body = {
          authType: 'MOBILE',
          tel: this.ruleForm.tel,
          captcha: this.ruleForm.captcha,
          userType: 'CLOUT_PERSON'
        }
      } else {
        let password = this.encryptedData(this.publicKey, this.ruleForm.password)
        body = {
          authType: 'ACCOUNT',
          tel: this.ruleForm.tel,
          captcha: this.ruleForm.captcha,
          userType: 'CLOUT_PERSON',
          loginName: this.ruleForm.userName,
          password,
          confirmPassword: password
        }
      }
      this.registerLoading = true
      this.$emit('handle-register-loading-change', this.registerLoading)
      this.$baseAppApi.USER.fetchUserRegister({}, body)
        .then(res => {
          if (res.code === 0) {
            this.$rootMethods.DATA_TRACKING.sendTrackData('1515002')
            this.isRegisterSuccess = true
            this.$emit('handle-register-success')
          }
        })
        .finally(() => {
          this.registerLoading = false
          this.$emit('handle-register-loading-change', this.registerLoading)
        })
    }
  }
}
</script>
<style lang="scss">
.gtm-login__register-from {
  .el-input__inner:hover {
    border-color: #165dff;
  }

  .tab-box {
    position: relative;
    width: 350px;
    top: 122px;
    left: 34px;

    .el-tabs__item {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }

    .el-tabs__item.is-active {
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #165dff;
    }

    .el-tabs__item:hover {
      color: #165dff;
    }

    .el-tabs__active-bar {
      padding: unset;
      left: unset;
      background-color: #165dff;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }

  .tab-box--username-register {
    position: relative;
    width: 350px;
    left: 34px;
    top: 100px;
  }

  .form-box {
    position: absolute;
    top: 196px;
    left: 50px;

    .el-input {
      width: 360px;
      height: 40px;
    }

    .register-form-box {
      height: 99px;

      .tel-form-item:hover {
        .tel-area-code-box {
          .el-input__inner {
            border-right: 1px solid #165dff;
            border-radius: 0px;
          }
        }

        .input-mobile {
          .el-input__inner {
            border-left: 1px solid #fff;
          }
        }
      }

      .tel-area-code-box {
        width: 76px;

        .el-input,
        .el-input__inner {
          width: 76px;
        }

        .el-input__inner {
          border-right: 1px solid #fff;
          border-radius: 0px;
        }
      }

      .verify-code-error-msg {
        height: 40px;
        margin-top: 10px;
        margin-bottom: -7px;
        line-height: 40px;
        background: rgba(86, 181, 2, 0.2);
        border-radius: 2px;
        border: 1px solid rgba(86, 181, 2, 0.3);
        font-size: 16px;
        font-weight: 400;
        color: #555555;
        display: flex;

        .el-icon-success {
          color: #56b502;
          font-size: 18px;
          margin: 10px 10px 0 20px;
        }
      }
    }

    .register-success {
      position: relative;
      top: -40px;

      .el-result {
        padding: 0;

        .el-result__title p {
          font-size: 20px;
        }

        .register__result-info {
          font-size: 16px;
        }
      }
    }

    .register-success-btn {
      button {
        width: 360px;
        height: 50px;
        background: #165dff;
        border-radius: 2px;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }

      .is-disabled {
        opacity: 0.4;
      }
    }

    .username-box {
      height: 320px;
    }

    .el-input-group__append {
      background-color: #fff;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #165dff;
      line-height: 24px;
      text-align: right;
      font-style: normal;
      margin-right: 20px;
      width: 52px;
      padding-left: 30px;
      height: 20px;

      .el-button {
        height: 20px;
        line-height: 0px;
        padding-left: 10px;
        border-left: 1px solid #d2d2d2;
        padding-right: 10px;
      }

      .is-disabled {
        color: #999999;
      }
    }

    .el-input__inner {
      height: 40px;
      line-height: 40px;
    }

    .el-form-item__error {
      background-color: unset;
    }

    .input-mobile {
      width: 286px;
      height: 40px;

      .el-input__inner {
        height: 40px;
        line-height: 40px;
        border-radius: 0px;
      }
    }

    .checkedClass {
      margin-top: 110px;

      .el-checkbox__label {
        span {
          color: #165dff;
        }
      }
    }

    .checkedClass--register {
      margin-top: 11px;
    }

    .input-password {
      .el-input__icon {
        margin-top: 2px;
      }
    }

    &.input-password::before {
      content: '';
      background: url(../../../../assets/images/gtm-login/gtm-login-password.png) center center no-repeat;
      background-size: 100% 100%;
      //width: 20px;
      //height: 20px;
      //bottom: 0px;
      //position: absolute;
      z-index: 1;
    }

    .register-btn {
      button {
        width: 360px;
        height: 50px;
        background: #165dff;
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }

      .is-disabled {
        opacity: 0.4;
      }
    }

    .register-btn:hover {
      button {
        background: rgba(22, 93, 255, 0.8);
      }
    }

    .register-btn:active {
      button {
        background: #124acc;
      }
    }

    .el-checkbox__label {
      font-size: 14px;
    }

    .captcha-input:hover {
      border-color: #165dff;
    }
  }

  .form-box--username-register {
    position: absolute;
    top: 174px;
    left: 50px;
  }

  .el-input {
    font-size: 14px;
  }

  .el-input__icon {
    width: 25px;
    line-height: 34px;
  }
}

.gtm-register-tooltip {
  width: 340px;
}
</style>
