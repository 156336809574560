<template>
  <div class="base-platform__gtm-login-footer">
    <div class="login-text top-line">
      <div v-if="menuList.includes('Home')" class="text-btn" @click="onGoToPage('portal/home', 'push')">金服首页</div>
      <div
        v-if="menuList.includes('InstitutionList')" class="text-btn"
        @click="onGoToPage('guarantee-web/institutionList', 'push')"
      >保函申请
      </div>
      <div v-if="menuList.includes('About')" class="text-btn" @click="onGoToPage('portal/about', 'push')">关于我们</div>
      <div class="text-btn" @click="onGoToGlodon">友情链接</div>
      <div class="text-btn" @click="onGoToPage('agreement', 'open')">法律声明</div>
      <div class="text-btn" @click="onGoToPage('privacy', 'open')">隐私条款</div>
    </div>
    <div class="login-text bottom-line">
      <div>©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司</div>
      <div>京ICP备10021606号-1 京公网安备11010802021391号</div>
      <div>主办单位：广联达科技股份有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginFooterCom',
  computed: {
    menuList () {
      return this.$rootState?.tenantConfig?.header?.menuList || ['Home', 'InstitutionList', 'About']
    }
  },
  methods: {
    onGoToPage (path, goToType) {
      if (goToType === 'open') {
        let { href } = this.$router.resolve({
          path: `/platform/${path}`
        })
        window.open(href, '_blank')
      } else if (goToType === 'push') {
        this.$router.push({
          path: `/platform/${path}`
        })
      }
    },
    onGoToGlodon () {
      window.open('https://www.glodon.com/', '_blank')
    }
  }
}
</script>
<style lang="scss">
.base-platform__gtm-login-footer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .login-text {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #333333;
    line-height: 16px;
    font-style: normal;
  }

  .top-line {
    display: flex;
    width: 100%;
    height: 16px;
    margin-top: calc(25 / 1920 * 100vw);
    justify-content: center;

    div {
      border-right: 1px solid #333333;
      padding-right: 15px;
      padding-left: 15px;
      cursor: pointer;
    }

    div:last-child {
      border: none;
    }

    .text-btn:hover {
      color: #165dff;
    }
  }

  .bottom-line {
    display: flex;
    width: 100%;
    height: 16px;
    margin-top: 10px;
    justify-content: center;

    div {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
</style>
